const useDropCalculations = (start, end, now) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const currentTime = new Date(now);
  const remaining = startTime - currentTime;
  const yetToStart = remaining < 0 ? false : true;
  const expired = endTime - currentTime < 0 ? true : false;
  return [yetToStart, expired];
};

export default useDropCalculations;
