import React from "react";

const CardButton = ({ text, handleClick, disable }) => {
  const disabledStyle = disable ? "bg-mutedGray" : "";
  return (
    <>
      <button
        className={`w-full p-4 bg-primary text-lg font-bold uppercase ${disabledStyle}`}
        onClick={handleClick}
        disabled={disable}
      >
        {text}
      </button>
    </>
  );
};

export default CardButton;
