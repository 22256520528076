import React, { useEffect } from "react";
import useCountdown from "../../hooks/useCountdown";

const ProductCountDown = ({ start, currentTime, yetToStart, updateStatus }) => {
  const [days, hours, minutes, seconds] = useCountdown(
    start,
    currentTime,
    yetToStart
  );

  useEffect(() => {
    if (days && hours && minutes && seconds) {
      if (days + hours + minutes + seconds <= 0) {
        updateStatus();
      }
    }
  }, [days, hours, minutes, seconds, updateStatus]);

  return (
    <>
      <div className="bg-bgDarkGray py-4 px-8 gap-y-4 flex justify-center items-center w-max flex-col">
        <span className="font-extrabold text-2xl">DROP AVAILABLE IN</span>
        <div className="flex gap-x-8 ">
          <div className="flex gap-x-1 items-center">
            <span className="text-lg font-bold text-primary">{days}</span>
            <span>d</span>
          </div>
          <div className="flex gap-x-1 items-center">
            <span className="text-lg font-bold text-primary">{hours}</span>
            <span>h</span>
          </div>
          <div className="flex gap-x-1 items-center">
            <span className="text-lg font-bold text-primary">{minutes}</span>
            <span>m</span>
          </div>
          <div className="flex gap-x-1 items-center">
            <span className="text-lg font-bold text-primary">{seconds}</span>
            <span>s</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCountDown;
