import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const GeneralModal = ({ hideModal, message, closeBtn = false }) => {
  return (
    <>
      <div className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70 z-50"></div>
      <div
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-mutedGray px-20 py-10"
        style={{ zIndex: "100" }}
      >
        {closeBtn && (
          <CloseIcon
            className="absolute top-4 right-4 cursor-pointer"
            onClick={hideModal}
          />
        )}
        <div className="flex flex-col items-center text-center gap-y-5">
          <p className="text-xl">{message}</p>
          {!closeBtn && (
            <span className="loader" style={{ background: "unset" }}></span>
          )}
        </div>
      </div>
    </>
  );
};

export default GeneralModal;
