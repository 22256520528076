import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDropCalculations from "../../hooks/useDropCalculations";
import { nftAxios } from "../../services/apiService";
import Button from "../atoms/Button";
import ProductCountDown from "../molecules/ProductCountDown";
import ProductRemaining from "../molecules/ProductRemaining";
import BuyNFTModal from "./BuyNFTModal";
import ImageViewer from "./ImageViewer";
import LoginModal from "./LoginModal";
import OwnedNFTTable from "./OwnedNFTTable";
import GeneralModal from "./GeneralModal";
import PdfReader from "./PdfReader";
import { RELOAD_REDUX_STATE } from "../../redux/types";
import { isUserAuthenticated } from "../../utils/utils";

const ProductCard = ({
  productPageImg,
  name,
  description,
  collectionName,
  tokenType,
  artists,
  editions,
  price,
  dropData,
  currentTime,
  tokenId,
  clientWallet,
  limitMaxClaim,
  maxClaimPerWallet,
  tokenTypeId,
  asset,
  location,
}) => {
  const redirectUrlFull = window.location.href;
  const redirectUrlFullBase = redirectUrlFull.split("?")[0];
  const userLoginState = useSelector((state) => state.isLogged);
  // const reduxState = useSelector((state) => state);
  const [dropStarted, setDropStarted] = useState();
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showGeneralModal, setShowGeneralModal] = useState(false);
  const [remaining, setRemaining] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [modalCloseBtnVisible, setModalCloseBtnVisible] = useState(false);
  const [boughtNFT, setBoughtNFT] = useState([]);

  const [comicUser, setComicUser] = useState(false);
  const [showComic, setShowComic] = useState(false);

  const [showBuyNow, setShowBuyNow] = useState(true);

  const [loadingRemaining, setLoadingRemaining] = useState(false);
  const [purchaseLimitReached, setPurchaseLimitReached] = useState(false);
  const [stripeResponse, setStripeResponse] = useState(false);

  const [yetToStart] = useDropCalculations(
    dropData.start,
    dropData.end,
    currentTime
  );

  const params = new URLSearchParams(location.search);
  const paymentsuccess = params.get("paymentsuccess");
  const paymenthash = params.get("paymenthash");

  // const dispatch = useDispatch();
  // const runSuccess = () => {
  //   const reduxState = sessionStorage.getItem("redux-state");
  //   if (reduxState) {
  //     dispatch({
  //       type: RELOAD_REDUX_STATE,
  //       reduxState: JSON.parse(reduxState),
  //     });
  //     sessionStorage.removeItem("redux-state");
  //     return true;
  //   }
  //   return false;
  // };
  const performRunSuccess = (success, hash) => {
    if (isUserAuthenticated() && success) {
      //Check hash exists and set token transfer on our system
      setModalMessage(`Payment received. Transferring NFT to your wallet.`);
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);

      //Then transfer token via blockchain and marketplace
      const chainData = {
        worldId: process.env.GATSBY_WORLD_ID,
        paymentHash: hash,
      };

      nftAxios
        .post(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/web3/transferNftToCustomer`,
          chainData
        )
        .then((response) => {
          if (response.data.status) {
            setModalMessage(`${response.data.message}`);
            setShowGeneralModal(true);
            setModalCloseBtnVisible(true);
            checkPurchsedNFT();
            return true;
          }

          setModalMessage(`${response.data.message}`);
          setShowGeneralModal(true);
          setModalCloseBtnVisible(true);
          checkPurchsedNFT();
          return false;
        })
        .catch((err) => {
          console.log("error occured", err);
          setModalMessage(
            `There was a problem transferring the NFT to your wallet. Please contact customer support.`
          );
          setShowGeneralModal(true);
          setModalCloseBtnVisible(true);
          return false;
        });
    }
  };
  useEffect(() => {
    if (stripeResponse) {
      performRunSuccess(stripeResponse.success, stripeResponse.hash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeResponse]);
  useEffect(() => {
    if ((paymentsuccess, paymenthash)) {
      // if (runSuccess(paymentsuccess, paymenthash))
      setStripeResponse({ success: paymentsuccess, hash: paymenthash });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRemainingCards = () => {
    setLoadingRemaining(true);
    axios
      .get(
        `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/getLinesCount/${tokenId}`
      )
      .then((response) => {
        const cardCount = response.data.genesis_drop_available;
        setRemaining(cardCount);
        setLoadingRemaining(false);
      })
      .catch((err) => {
        console.log("some error occured:", err);
        setLoadingRemaining(false);
      });
  };
  useEffect(() => {
    if (tokenId) {
      getRemainingCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  const checkPurchsedNFT = () => {
    nftAxios
      .get(
        `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/producttokenlines/owned/${tokenId}`
      )
      .then((response) => {
        //Set the comic user to be able to read the comic
        if (
          response.data.length &&
          tokenTypeId === +process.env.GATSBY_TOKEN_TYPE_COMIC
        ) {
          setComicUser(true);
        }

        setBoughtNFT(response.data);
      })
      .catch((err) => {
        console.log("error occured", err);
      });
  };

  useEffect(() => {
    if (isUserAuthenticated() && tokenId) {
      //Check if the user is able to buy - if not then do not show the buy button
      nftAxios
        .get(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/userTokenLimitReached/${tokenId}`
        )
        .then((response) => {
          response.data.status ? setShowBuyNow(false) : setShowBuyNow(true);
        })
        .catch((err) => {
          console.log("error occured", err);
        });

      checkPurchsedNFT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginState, tokenId]);

  useEffect(() => {
    if (yetToStart !== undefined) {
      if (!yetToStart) {
        setDropStarted(true);
      }
      setDropStarted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yetToStart]);

  useEffect(() => {
    if (limitMaxClaim && boughtNFT && maxClaimPerWallet) {
      if (boughtNFT.length === maxClaimPerWallet) {
        setPurchaseLimitReached(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boughtNFT, limitMaxClaim, maxClaimPerWallet]);

  const handleBuyNow = () => {
    if (isUserAuthenticated()) {
      setShowBuyNowModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleRead = () => {
    setShowComic(true);
  };

  const buyWithStripe = async () => {
    setModalCloseBtnVisible(false);
    setShowBuyNowModal(false);
    setModalMessage(`Processing Stripe payment...`);
    setShowGeneralModal(true);

    if (
      userLoginState.userAccountId.toUpperCase() === clientWallet.toUpperCase()
    ) {
      setShowBuyNowModal(false);
      setModalMessage("You may not buy tokens from yourself!");
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    }

    //Need to lock the token.
    const reserveData = {
      tokenId: tokenId,
      buyerId: userLoginState.user.id,
    };

    let reserveToken;

    try {
      setModalMessage("Locking the token for you...");
      setShowGeneralModal(true);

      reserveToken = await nftAxios.post(
        `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/reserveToken`,
        reserveData
      );

      if (!reserveToken.data.status) {
        setModalMessage(`We were unable to lock the token for you.`);
        setShowGeneralModal(true);
        setModalCloseBtnVisible(true);
        return false;
      }
    } catch (err) {
      console.log(err);
      setModalMessage(`We were unable to lock the token for you.`);
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    }

    try {
      if (reserveToken.data.status) {
        setModalMessage(reserveToken.data.message);
        setShowGeneralModal(true);
        setModalCloseBtnVisible(true);
      }
    } catch (err) {
      console.log(err);
      setModalMessage(`We were unable to lock the token for you.`);
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    }

    try {
      setModalMessage("Preparing to redirect you to Stripe Payments.");
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);

      const sessionData = {
        tokenId: tokenId,
        marketplaceSellingId: reserveToken.data.data.marketplaceSellingsId,
        callbackUrl: redirectUrlFullBase,
      };
      // sessionStorage.setItem("redux-state", JSON.stringify(reduxState));
      const sessionUrl = await nftAxios.post(
        `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/payment/createCheckoutSession`,
        sessionData
      );

      if (sessionUrl.data.status) {
        window.location.href = sessionUrl.data.data.url;
        return true;
      }

      setModalMessage(
        `There was a problem bringing up Stripe Payments. Please try paying via Crypto instead.`
      );
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    } catch (err) {
      console.log(err);
      setModalMessage(
        `There was a problem bringing up Stripe Payments. Please try paying via Crypto instead.`
      );
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    }

    // setModalMessage(
    //   `There was a problem with your payment. Please contact customer support.`
    // );
    // setShowGeneralModal(true);
    // setModalCloseBtnVisible(true);
    // return false;

    // try {
    //   transferToken = await nftAxios.post(
    //     `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/web3/verifyAndTransferOnChain`,
    //     transferData
    //   );
    //
    //   setModalMessage("Congratulations. NFT transferred to your wallet.");
    //   setModalCloseBtnVisible(true);
    //   getRemainingCards();
    // } catch (err) {
    //   console.log(err);
    //   return false;
    // }
  };

  const buyWithCrypto = (recipientAddress, decimalAmount, aspenTokenId) => {
    setModalCloseBtnVisible(false);
    setShowBuyNowModal(false);
    setModalMessage(`Processing crypto payment...`);
    setShowGeneralModal(true);

    const cryptoAmount = Math.floor(
      parseInt(process.env.GATSBY_CRYPTO_MULTIPLIER) * parseFloat(decimalAmount)
    );

    if (
      userLoginState.userAccountId.toUpperCase() ===
      recipientAddress.toUpperCase()
    ) {
      setShowBuyNowModal(false);
      setModalMessage("You may not buy tokens from yourself!");
      setShowGeneralModal(true);
      setModalCloseBtnVisible(true);
      return false;
    }

    window.ethereum
      .request({
        method: "eth_sendTransaction",
        params: [
          {
            from: userLoginState.userAccountId,
            to: recipientAddress, //recipient
            value: "0x" + cryptoAmount.toString(16),
          },
        ],
      })
      .then(async (txHash) => {
        //data, userid, tokenId, type, status
        const recordData = {
          tokenId: aspenTokenId,
          userid: userLoginState.user.id,
          status: "initiated",
          type: "crypto",
          data: {
            txId: txHash,
            decimalAmount: decimalAmount,
          },
        };

        //Record the payment - synchronous since we don't care about a return
        nftAxios.post(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/payment/record`,
          recordData
        );

        //hide the payment modal
        setShowBuyNowModal(false);
        let reserveToken;

        const reserveData = {
          tokenId: aspenTokenId,
          buyerId: userLoginState.user.id,
        };

        try {
          setModalMessage("Locking the token for you...");
          setShowGeneralModal(true);

          reserveToken = await nftAxios.post(
            `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/reserveToken`,
            reserveData
          );

          if (!reserveToken.data.status) {
            setModalMessage(
              `We were unable to lock the token. Please contact customer support and quote transaction ID: ${txHash}`
            );
            setShowGeneralModal(true);
            setModalCloseBtnVisible(true);
            return false;
          }

          setModalMessage(
            "Transferring the NFT to your wallet... please wait."
          );
        } catch (err) {
          console.log(err);
          setModalMessage(
            `We were unable to lock the NFT. Please contact customer support and quote transaction ID: ${txHash}`
          );
          setShowGeneralModal(true);
          setModalCloseBtnVisible(true);
          return false;
        }

        //Transfer the token to the buyer
        const transferData = {
          transactionId: txHash,
          marketplaceSellingId: reserveToken.data.data.marketplaceSellingsId,
          worldId: process.env.GATSBY_WORLD_ID,
        };
        console.log(transferData);

        let transferToken;

        try {
          transferToken = await nftAxios.post(
            `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/web3/verifyAndTransferOnChain`,
            transferData
          );

          console.log("--------------------------------");
          console.log(transferToken);
          console.log("--------------------------------");

          if (!transferToken) {
            setModalMessage(
              "Payment was successful, but unable to transfer NFT to your wallet. Please contact customer support."
            );
            setModalCloseBtnVisible(true);
          }

          if (!transferToken) {
            setModalMessage(
              "Payment was successful, but unable to transfer NFT to your wallet. Please contact customer support."
            );
            setModalCloseBtnVisible(true);
          } else if (transferToken.data?.status === false) {
            setModalMessage(transferToken.data?.message);
            setModalCloseBtnVisible(true);
          } else {
            setModalMessage(
              "Congratulations! Your new NFT has been transferred to your wallet."
            );
            setModalCloseBtnVisible(true);
          }

          checkPurchsedNFT();
          getRemainingCards();
        } catch (err) {
          console.log(err);
          return false;
        }
      })
      .catch((error) => {
        setShowBuyNowModal(false);
        console.log(error);
        setModalMessage(
          "There was a problem submitting your payment. Tokens and payment not transferred."
        );
        setShowGeneralModal(true);
        setModalCloseBtnVisible(true);
      });
  };
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-y-4 md:gap-y-0 md:flex-row">
        <div className="lg:w-[40%] lg:pr-20 md:w-1/2 md:pr-10 h-[300px] sm:h-[500px] md:h-auto">
          <ImageViewer imgLink={productPageImg}>
            <div
              className="w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${productPageImg})`,
              }}
            ></div>
          </ImageViewer>
        </div>
        <div className="lg:w-[60%] md:w-1/2 flex flex-col gap-y-6 items-start">
          <div className="flex flex-col gap-y-2 item-start">
            <span className="text-primary text-lg font-bold">
              {collectionName}
            </span>
            <h2 className="font-extrabold text-4xl mt-2">{name}</h2>
            <div>
              <span className="text-primary">By: </span>
              {artists.map((artist) => {
                return (
                  <span key={artist} className="">
                    {artist}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div>
              <span className="text-primary text-lg font-bold w-[100px] inline-block">
                Editions:
              </span>
              {editions}
            </div>
            <div>
              <span className="text-primary text-lg font-bold w-[100px] inline-block">
                Type:
              </span>
              {tokenType}
            </div>
            <div>
              <span className="text-primary text-lg font-bold w-[100px] inline-block">
                Price:
              </span>
              <span>
                USD ${price.fiat} / {price.crypto} MATIC
              </span>
            </div>
            <div>
              <span className="text-primary text-lg font-bold flex flex-col">
                Description:
              </span>
              <span>{description}</span>
            </div>

            {comicUser ? (
              <div>
                <Button onClick={handleRead} arrow={true}>
                  Read Comic
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className=" flex justify-center items-center w-max flex-col gap-y-1 mx-auto md:mx-0">
            {dropStarted !== undefined && (
              <>
                {dropStarted ? (
                  <div>
                    <ProductRemaining
                      loading={loadingRemaining}
                      dropEnd={dropData.end}
                      remainingCards={remaining}
                    />
                    {remaining !== null && !purchaseLimitReached && (
                      <>
                        {remaining > 0 && showBuyNow ? (
                          <Button onClick={handleBuyNow} arrow={true}>
                            Buy Now
                          </Button>
                        ) : (
                          <Button className="bg-gray text-mutedGray cursor-not-allowed">
                            {showBuyNow ? "Sold Out" : "Limit Reached"}
                          </Button>
                        )}
                      </>
                    )}
                    {purchaseLimitReached && (
                      <div className="flex gap-x-4">
                        <Button className="bg-gray text-mutedGray cursor-not-allowed">
                          Limit Reached
                        </Button>
                        <div className="italic">
                          <span>*Sale limited to </span>
                          <span className="text-primary">
                            {maxClaimPerWallet}{" "}
                          </span>
                          <span>per user</span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <ProductCountDown
                      start={dropData.start}
                      currentTime={currentTime}
                      yetToStart={yetToStart}
                      updateStatus={setDropStarted.bind(this, true)}
                    />
                    {limitMaxClaim && (
                      <div className="italic">
                        *Sale limited to
                        <span className="text-primary">
                          {" "}
                          {maxClaimPerWallet}{" "}
                        </span>
                        per user
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {boughtNFT.length > 0 && <OwnedNFTTable boughtNFT={boughtNFT} />}
      {showBuyNowModal && (
        <BuyNFTModal
          cryptoBuy={buyWithCrypto.bind(
            this,
            clientWallet,
            price.crypto,
            tokenId
          )}
          stripeBuy={buyWithStripe.bind(this)}
          hideModal={setShowBuyNowModal.bind(this, false)}
        />
      )}
      {showLoginModal && (
        <LoginModal
          handleCloseModal={setShowLoginModal.bind(this, false)}
          showLoginModal={showLoginModal}
        />
      )}
      {showComic && (
        <PdfReader hideModal={setShowComic.bind(this, false)} asset={asset} />
      )}

      {showGeneralModal && (
        <GeneralModal
          hideModal={setShowGeneralModal.bind(this, false)}
          message={modalMessage}
          closeBtn={modalCloseBtnVisible}
        />
      )}
    </div>
  );
};

export default ProductCard;
