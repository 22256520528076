import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageViewer = ({ children, imgLink = "", imageList = [] }) => {
  const [popup, setPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = (e) => {
    e.stopPropagation();
    if (imageList.length) {
      if (currentIndex < imageList.length) {
        setCurrentImage(imageList[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentImage(imageList[0]);
        setCurrentIndex(0);
      }
    }
  };
  const prevImage = (e) => {
    e.stopPropagation();
    if (imageList.length) {
      if (currentIndex > 0) {
        setCurrentImage(imageList[currentIndex - 1]);
        setCurrentIndex(currentIndex - 1);
      } else {
        setCurrentImage(imageList[imageList.length - 1]);
        setCurrentIndex(imageList.length - 1);
      }
    }
  };

  useEffect(() => {
    if (imgLink) setCurrentImage(imgLink);
    if (imageList.length) {
      const index = imageList.indexOf((item) => item === imgLink);
      setCurrentIndex(index);
    }
  }, [imgLink, imageList]);

  return (
    <>
      <div
        className="cursor-pointer w-full h-full"
        onClick={() => setPopup(true)}
        role="button"
        onKeyDown={() => setPopup(true)}
        tabIndex={0}
      >
        {children}
      </div>
      {popup && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 "
          style={{ zIndex: "999999" }}
        >
          <div
            className="flex justify-between items-center p-16 w-full h-full bg-black bg-opacity-50"
            onClick={() => setPopup(false)}
            role="button"
            onKeyDown={() => setPopup(false)}
            tabIndex={-1}
          >
            <span
              className="p-4 cursor-pointer"
              onClick={prevImage}
              role="button"
              onKeyDown={prevImage}
              tabIndex={-2}
            >
              <ArrowBackIosIcon />
            </span>
            <div
              className="p-20"
              onClick={(e) => e.stopPropagation()}
              role="button"
              onKeyDown={(e) => e.stopPropagation()}
              tabIndex={-3}
            >
              <TransformWrapper>
                <TransformComponent>
                  <img src={currentImage} alt="demo" />
                </TransformComponent>
              </TransformWrapper>
            </div>
            <span
              className="p-4 cursor-pointer"
              onClick={nextImage}
              role="button"
              onKeyDown={nextImage}
              tabIndex={-4}
            >
              <ArrowForwardIosIcon />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageViewer;
