import React from "react";
import img1 from "../../assets/images/LoadingComic.svg";

const PdfReader = ({ asset, hideModal }) => {
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="bg-primary font-bold p-2"
        style={{
          position: "fixed",
          bottom: "25px",
          zIndex: "999999",
          cursor: "pointer",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={hideModal}
        onKeyDown={hideModal}
      >
        Hide Comic
      </div>
      <div
        style={{
          position: "fixed",
          top: "10px",
          bottom: "10px",
          width: "97%",
          height: "97%",
          border: "none",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          zIndex: "999998",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#000",
        }}
      >
        <iframe
          title="Comic"
          src={`${asset}#view=fitH`}
          style={{
            zIndex: "90000",
            width: "100%",
            height: "100%",
            background: `url(${img1}) center center no-repeat`,
          }}
        />
      </div>
    </>
  );
};

export default PdfReader;
