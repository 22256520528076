/**
  WARNING!!! This file should only be altered for boilerplate.
  Changes will be propagated to all sites.
**/

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ProductCard from "../../components/organisms/ProductCard";
import TwoColorHeader from "../../components/atoms/TwoColorHeader";
import { Container } from "@mui/system";
import axios from "axios";
import NftCardDetailBuy from "../../components/molecules/nftCards/NftCardDetailBuy";
import NftGridLayout from "../../components/molecules/NftGridLayout";

import { Helmet } from "react-helmet";

const ProductPage = ({ pageContext, location }) => {
  const [serverTime, setServerTime] = useState();

  const asset = pageContext.product?.aspen_token_assets[0]?.aspen_url;

  let collectionSlug;

  try {
    collectionSlug = pageContext?.collection[0]?.slug;
  } catch (err) {
    collectionSlug = "";
  }

  useEffect(() => {
    try {
      axios
        .get(
          `${process.env.GATSBY_STRAPI_API_URL}/api/marketplace/getServerTime`
        )
        .then((res) => {
          setServerTime(res.data.serverTime);
        });
    } catch (err) {
      const nowDatetime = new Date();
      const isoDatetime = nowDatetime.toISOString();
      setServerTime(isoDatetime);
    }
  }, []);

  let aspenCollections = [];
  if (pageContext.collection !== undefined) {
    if (pageContext?.collection.length > 0) {
      aspenCollections = pageContext?.collection[0]?.aspen_collections;
    }
  }

  let collections = [];

  if (aspenCollections) {
    collections = aspenCollections
      .filter((item) => item?.aspen_tokens[0]?.id !== pageContext?.product?.id)
      .slice(0, 3);
  }

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.Description}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}
        />
        <meta
          name="twitter:description"
          content={`${pageContext?.product?.Description} | ${pageContext?.product?.name} NFT`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageContext?.product?.name} NFT`}</title>
      </Helmet>
      <Container maxWidth="lg" className="mt-48 mb-24">
        <div className="mb-16">
          {serverTime && (
            <ProductCard
              location={location}
              productPageImg={pageContext?.product?.featured_image?.url}
              name={pageContext?.product?.name}
              description={pageContext?.product?.Description}
              collectionName={
                pageContext?.product?.aspen_collection?.artefy_collection?.name
              }
              tokenType={pageContext?.product?.aspen_token_type?.name}
              artists={pageContext?.artists}
              editions={pageContext?.product?.aspen_token_lines?.length}
              price={{
                crypto: pageContext?.product?.drop_price_crypto,
                fiat: pageContext?.product?.drop_price_fiat,
              }}
              dropData={{
                start: pageContext?.product?.drop?.drop_start,
                end: pageContext?.product?.drop?.drop_end,
              }}
              currentTime={serverTime}
              tokenId={pageContext?.product?.id}
              clientWallet={
                pageContext?.product?.aspen_collection?.client_wallet
              }
              tokenTypeId={pageContext?.product?.aspen_token_type?.id}
              asset={asset}
              limitMaxClaim={
                pageContext?.product?.aspen_collection?.limit_max_claim
              }
              maxClaimPerWallet={
                pageContext?.product?.aspen_collection?.max_claim_per_wallet
              }
            />
          )}
        </div>

        <div className="flex flex-col items-center gap-y-4">
          <TwoColorHeader
            title="More from|this collection"
            colorClassB="text-primary"
          />
          <NftGridLayout contentLength={collections.length}>
            {collections.map((item) => {
              const token = item?.aspen_tokens[0];
              if (!token) {
                return null;
              }
              const tokenLink = `/marketplace/nfts/${collectionSlug}/${token?.slug}`;
              return (
                <NftCardDetailBuy
                  key={token?.id}
                  name={token?.name}
                  nftCardImg={token?.featured_image?.url}
                  priceCrypto={token?.drop_price_crypto}
                  priceFiat={token?.drop_price_fiat}
                  artists={token?.artists}
                  totalTokens={token?.aspen_token_lines?.length}
                  tokenId={token?.id}
                  tokenType={token?.aspen_token_type?.name}
                  link={tokenLink}
                />
              );
            })}
          </NftGridLayout>
        </div>
      </Container>
    </Layout>
  );
};

export default ProductPage;
