// import axios from "axios";
import React from "react";

const ProductRemaining = ({ remainingCards, dropEnd, loading }) => {
  return (
    <>
      <div className="bg-bgDarkGray py-4 px-8 gap-y-4 flex justify-center items-center w-max flex-col">
        <span className="font-extrabold text-2xl">NOW AVAILABLE</span>
        <div>
          <span className="text-lg font-bold text-primary">
            {loading ? (
              <span className="loader !w-4 !h-4 inline-block"></span>
            ) : (
              remainingCards
            )}
          </span>
          <span> Remaining Copies</span>
        </div>
        {/* <span>Ends: {new Date(dropEnd).toUTCString()}</span> */}
      </div>
    </>
  );
};

export default ProductRemaining;
