import React from "react";

const OwnedNFTTable = ({ boughtNFT }) => {
  return (
    <>
      <div className="mt-16">
        <h2 className="p-4 bg-primary">Editions you own</h2>
        <table className="w-full border border-primary">
          <thead>
            <tr className="text-gray">
              <th className="text-left px-4">Edition</th>
              <th className="text-left px-4">Date Acquired</th>
              <th className="text-left px-4">Purchased Price</th>
            </tr>
          </thead>
          <tbody>
            {boughtNFT.map((nft) => {

              const edition = nft?.aspen_array_key || "-";
              const acquired = nft?.marketplace_sellings[0]?.transfer_date || "-";

              const newDate = new Date(acquired);
              const dateShow = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' +  newDate.getFullYear()

              const cryptoPrice =
                nft?.marketplace_sellings[0]?.price_crypto || "-";
              const usdPrice = nft?.marketplace_sellings[0]?.price_usd || "-";
              return (
                <tr key={nft.id} className="bg-mutedGray">
                  <td className="px-4 py-2">#{parseInt(edition) + 1}</td>
                  <td className="px-4 py-2">{dateShow}</td>
                  <td className="px-4 py-2">
                    {cryptoPrice}LYS / ${usdPrice}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OwnedNFTTable;
