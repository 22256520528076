import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardButton from "./CardButton";
import axios from "axios";
import { Link } from "gatsby";

const NftCardDetailBuy = ({
  name = "",
  nftCardImg = "",
  priceCrypto = 0,
  priceFiat = 0,
  artists = [],
  totalTokens = 0,
  tokenId,
  link,
}) => {
  const [remainingCards, setRemainingCards] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tokenId) {
      setLoading(true);
      axios
        .get(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/getLinesCount/${tokenId}`
        )
        .then((response) => {
          const cardCount = response.data.genesis_drop_available;
          setRemainingCards(cardCount);
          setLoading(false);
        })
        .catch((err) => {
          console.log("some error occured:", err);
          setLoading(false);
        });
    }
  }, [tokenId]);

  return (
    <>
      <div className="min-w-[290px] max-w-[420px] mx-auto sm:mx-0 w-full">
        <div className="relative">
          <div
            className="w-full h-[400px] bg-center bg-cover"
            style={{ backgroundImage: `url(${nftCardImg})` }}
          ></div>
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-70 p-4">
            <h3
              className=" text-2xl font-bold mb-2 uppercase"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                height: "32px",
              }}
            >
              {name}
            </h3>
            <div className="flex justify-between">
              <span className="text-center font-medium">
                By:{" "}
                <span className="text-red font-bold">
                  {artists.map((artist) => {
                    return (
                      <span key={artist.id}>
                        {artist?.first_name} {artist?.surname}
                      </span>
                    );
                  })}
                </span>
              </span>
              <h4 className="text-lg font-medium">
                {priceCrypto} {process.env.GATSBY_ACTIVE_CRYPTO_NAME}
              </h4>
            </div>
            <div className="flex justify-between">
              <span className="text-center font-medium">
                Type: <span className="font-bold">NFT Book</span>
              </span>
              <div>
                {loading ? (
                  <span className="loader !w-4 !h-4 inline-block"></span>
                ) : (
                  remainingCards
                )}
                / {totalTokens}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pr-2">
          <div className="w-1/3">
            <Link to={link}>
              <CardButton
                text="BUY"
                disable={remainingCards === 0 ? true : false}
              />
            </Link>
          </div>
          <div className="text-primary text-lg font-bold cursor-pointer">
            <Link to={link}>
              View <ArrowForwardIcon />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftCardDetailBuy;
