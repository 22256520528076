import React from "react";
// import { Container } from "@mui/system";

const NftGridLayout = ({ contentLength = 4, children }) => {
  return (
    <>
      <div
        className={`w-[90%] max-w-[1900px] mx-auto sm:p-4 md:p-12 lg:p-16 ${
          contentLength === 1 ? "max-w-[500px]" : ""
        } ${contentLength === 2 ? "max-w-[1000px] " : ""} ${
          contentLength === 3 ? "max-w-[1300px] " : ""
        } `}
      >
        <div
          className={`space-y-12 sm:grid  sm:gap-x-7 sm:gap-y-16 sm:space-y-0 justify-center  ${
            contentLength >= 4
              ? "sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
              : ""
          } ${contentLength === 3 ? "sm:grid-cols-2 xl:grid-cols-3" : ""} ${
            contentLength === 2 ? "sm:grid-cols-2 " : ""
          } ${contentLength === 1 ? "sm:grid-cols-1" : ""}`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default NftGridLayout;
