import React from "react";
import { useSelector } from "react-redux";
import Button from "../atoms/Button";
import { isUserEmailUpdated } from "../../utils/utils";
import { Link } from "gatsby";

const BuyNFTModal = ({ hideModal, cryptoBuy, stripeBuy }) => {
  const userLoginState = useSelector((state) => state.isLogged);
  return (
    <>
      <div
        className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70 z-50"
        onClick={hideModal}
        role="button"
        onKeyDown={hideModal}
        tabIndex={0}
        aria-label="Close"
      ></div>
      <div
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-mutedGray px-20 py-10"
        style={{ zIndex: "100" }}
      >
        <div className="flex flex-col items-center gap-y-5">
          <p className="text-xl">Select payment channel</p>
          <div className="flex gap-x-5">
            <Button onClick={stripeBuy} disabled={!isUserEmailUpdated() ||
              !userLoginState.isUserLoggedInToMetaMask ||
              !userLoginState?.chainStatus?.isSupportedChain}>
              Credit Card
            </Button>
            <Button onClick={cryptoBuy} disabled={!isUserEmailUpdated() ||
              !userLoginState.isUserLoggedInToMetaMask ||
              !userLoginState?.chainStatus?.isSupportedChain}>
              MATIC
            </Button>
          </div>
          {isUserEmailUpdated() ? (
            <></>
          ) : (
            <p className="text-xs">
              <Link to={"/register"}>
                Please update your profile to continue.
              </Link>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default BuyNFTModal;
