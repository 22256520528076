import * as React from "react";

export default function TwoColorHeader({
  title,
  colorClassB,
  type = "standard",
  sizeClass = "text-4xl",
  className = "",
  whiteCount = 1,
}) {
  const titleArr = title.split("|");

  let titleHighlighted = "";

  if (titleArr.length > 1) {
    titleHighlighted = titleArr.pop();
  }

  const titleNormal = titleArr.join(" ");

  return (
    <>
      <h2
        className={`${sizeClass} text-center font-primary capitalize font-extrabold ${className}`}
      >
        <span>{titleNormal}</span>
        <span className={colorClassB}> {titleHighlighted}</span>
      </h2>
    </>
  );
}
