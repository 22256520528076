const axios = require("axios");

const getter = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (err) {
    console.log("error");
    return false;
  }
};

// const getterSharedAuthAxios = async (url) => {
//   try {
//     config
//     const response = await sharedAuthAxios.get(url);
//     return response;
//   } catch (err) {
//     console.log("error");
//     return false;
//   }
// };

const nftAxios = axios.create({
  baseURL: process.env.GATSBY_SHARED_STRAPI_ASSET_URL,
});
const getJWT = () => {
  const artefyUser = sessionStorage.getItem("artefyUser");
  const artefyUserJSON = JSON.parse(artefyUser);
  return artefyUserJSON.jwt;
}

nftAxios.interceptors.request.use(
  
  async config => {
    config.headers = {
      Authorization: `Bearer ${getJWT()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

nftAxios.interceptors.response.use(
  response => response,
  async error => {
    // whatever you want to do with the error
    if (error.response?.status === 401) {
     sessionStorage.removeItem("nftEngineJwt");
    //await authAndSetJwt();
    }

    throw error;
  }
);

module.exports = { getter, nftAxios };
